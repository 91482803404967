import { Controller } from "stimulus"

export default class extends Controller {
  track_link(event) {
    event.preventDefault();

    const url = this.element.href
    const parsedParams = JSON.parse(this.element.dataset.params)
    const data = new FormData()

    data.append('analytics[event]', parsedParams.event)
    Object.keys(parsedParams.properties).forEach(key => data.append(`analytics[properties][${ key }]`, parsedParams.properties[key])) // Convert all properties from parsed JSON to FormData

    Rails.ajax({
      type: "post",
      url: "/analytics/track",
      dataType:"json",
      data: data
    })

    if (this.element.target == '_blank') {
      window.open(url)
    } else {
      location.href = url
    }
  }
}
